import React from 'react';
import styled from 'styled-components';

const ListItem = styled.li`
  padding: 0.25rem 0;
`;

const SubList = styled.ul`
  padding-left: 1.5rem;
`;

const Heading = styled.h2`
  margin: 1rem 0 0.5rem;
`;

const SubHeading = styled.h3`
  margin: 0.5rem 0;
`;

const Paragraph = styled.p`
  margin: 0.5rem 0;
`;

export const PrivacyPolicyContent: React.FunctionComponent = () => {
  return (
    <div>
      <Paragraph>
        <strong>Effective Date:</strong> 5 January 2022
      </Paragraph>
      <Paragraph>
        WDAS Technologies (Pty) Ltd (“WDAS Technologies”, “we”, “us”, or “our”)
        respects your privacy and is committed to protecting your personal
        information. This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your information in compliance with the
        Protection of Personal Information Act, 2013 (POPIA) and other
        applicable laws in South Africa.
      </Paragraph>
      <ol>
        <ListItem>
          <Heading>Information We Collect</Heading>
          <SubList>
            <ListItem>
              Personal Identification Information: Name, email address, phone
              number, and other contact details.
            </ListItem>
            <ListItem>
              Demographic Information: Age, gender, location, and preferences.
            </ListItem>
            <ListItem>
              Transactional Data: Details about services or products you have
              purchased.
            </ListItem>
            <ListItem>
              Technical Information: IP address, browser type, operating
              system, and browsing behaviour on our website.
            </ListItem>
            <ListItem>
              Cookies and Tracking Data: Information from cookies and similar
              tracking technologies used to enhance your website experience.
            </ListItem>
          </SubList>
        </ListItem>

        <ListItem>
          <Heading>How We Collect Information</Heading>
          <SubList>
            <ListItem>
              Direct interactions: When you contact us, register for services,
              or fill out forms on our website.
            </ListItem>
            <ListItem>
              Automated technologies: Through cookies, server logs, and
              analytics tools when you browse our website.
            </ListItem>
            <ListItem>
              Third-party sources: Publicly available data or from third-party
              service providers with your consent.
            </ListItem>
          </SubList>
        </ListItem>

        <ListItem>
          <Heading>How We Use Your Information</Heading>
          <SubList>
            <ListItem>To provide and manage our services.</ListItem>
            <ListItem>
              To communicate with you, including sending updates, invoices, or
              promotional materials.
            </ListItem>
            <ListItem>
              To improve our website, products, and services through analytics
              and feedback.
            </ListItem>
            <ListItem>
              To comply with legal obligations and enforce our terms of service.
            </ListItem>
            <ListItem>
              To protect against fraud, unauthorized access, and other security
              threats.
            </ListItem>
          </SubList>
        </ListItem>

        <ListItem>
          <Heading>Sharing Your Information</Heading>
          <SubList>
            <ListItem>
              <strong>Service Providers:</strong> Third-party vendors who
              assist in delivering our services (e.g., payment processors, IT
              support).
            </ListItem>
            <ListItem>
              <strong>Legal Authorities:</strong> When required by law or to
              protect our legal rights.
            </ListItem>
            <ListItem>
              <strong>Business Partners:</strong> With your consent, for
              marketing or partnership purposes.
            </ListItem>
          </SubList>
          <Paragraph>
            We do not sell or rent your personal information to third parties.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Your Rights Under POPIA</Heading>
          <SubList>
            <ListItem>
              <strong>Access and Correction:</strong> Request access to your
              personal information or request corrections.
            </ListItem>
            <ListItem>
              <strong>Objection:</strong> Object to the processing of your
              personal data in certain circumstances.
            </ListItem>
            <ListItem>
              <strong>Withdrawal of Consent:</strong> Withdraw your consent for
              specific processing activities.
            </ListItem>
            <ListItem>
              <strong>Data Portability:</strong> Request transfer of your data
              to another service provider where feasible.
            </ListItem>
          </SubList>
          <Paragraph>
            To exercise these rights, please contact us at
            info@paycheck.co.za.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Data Retention</Heading>
          <Paragraph>
            We retain your personal information only for as long as necessary
            to fulfil the purposes outlined in this policy or as required by
            law.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Cookies and Tracking Technologies</Heading>
          <SubList>
            <ListItem>Enhance user experience.</ListItem>
            <ListItem>Analyze website usage and trends.</ListItem>
            <ListItem>Provide targeted advertisements.</ListItem>
          </SubList>
          <Paragraph>
            You can manage your cookie preferences through your browser
            settings.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Data Security</Heading>
          <Paragraph>
            We implement appropriate technical and organizational measures to
            safeguard your personal information from unauthorized access, loss,
            or misuse. However, no method of transmission over the internet is
            100% secure, and we cannot guarantee absolute security.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Third-Party Links</Heading>
          <Paragraph>
            Our website may contain links to third-party websites. This Privacy
            Policy does not apply to those websites, and we are not responsible
            for their privacy practices. We encourage you to review the privacy
            policies of any third-party sites you visit.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Changes to This Privacy Policy</Heading>
          <Paragraph>
            We may update this Privacy Policy from time to time. The updated
            policy will be posted on this page with the “Effective Date” noted
            above. We encourage you to review this policy periodically.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Contact Us</Heading>
          <Paragraph>
            If you have any questions about this Privacy Policy or how we
            handle your personal information, please contact us:
          </Paragraph>
          <SubList>
            <ListItem>WDAS Technologies (Pty) Ltd</ListItem>
            <ListItem>Email: info@paycheck.co.za</ListItem>
            <ListItem>Phone: +27 87 654 7686</ListItem>
          </SubList>
        </ListItem>
      </ol>
    </div>
  );
};
