import * as React from "react"
import Modal from "../../Modal"
import TextButton from "../../elements/TextButton"
import { formatAmount } from "../../../utils/format"
import Disclaimer from "../Disclaimer"
import { red } from "@material-ui/core/colors"
import { CREATE_PAYCHECK_INDEX_REQUEST } from "../graphql"
import { useMutation } from "@apollo/react-hooks"
import { PrivacyPolicyContent } from "../terms&policies/policy"

interface PaycheckIndexModalProps {
  displayPaycheckIndexModel: boolean
  employeeId: string
  handleOnClose: () => void
}

export const PaycheckIndexModal: React.FunctionComponent<PaycheckIndexModalProps> = ({
  displayPaycheckIndexModel,
  employeeId,
  handleOnClose,
}: PaycheckIndexModalProps) => {
  const [createPaycheckIndex, createPaycheckIndexRes] = useMutation(
    CREATE_PAYCHECK_INDEX_REQUEST,
  )
  const [isProcessed, setIsProcessed] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  const handleRequireAssistance = () => {
    createPaycheckIndex({
      variables: {
        employee: employeeId,
      },
    })
      .then((data: any) => {
        setHasError(!data?.data?.createIndexRequest?.success)
        setIsProcessed(true)
      })
      .catch((reason) => {
        setHasError(true)
      })
  }
  const handleDoneClick = () => {
    setIsProcessed(false)
    setHasError(false)
    handleOnClose()
  }
  return (
    <Modal
      open={displayPaycheckIndexModel}
      onClose={handleOnClose}
      title={
        isProcessed
          ? hasError
            ? "Warning"
            : "Request submitted!"
          : "PayCheck Approved! Payment will be released"
      }
      loading={createPaycheckIndexRes.loading}
      fullWidth={false}
      disableBackdropClose={true}
    >
      <br />

      {isProcessed ? (
        <div>
          {hasError ? (
            <span>
              We regret to inform you that your request for financial assistance
              could not be successfully submitted at this time. Please contact
              our support team for further assistance.
            </span>
          ) : (
            <span>
              Thank you for your interest, we look forward to starting your
              journey to become financially free. An experienced representative
              will be contacting you soonest.
            </span>
          )}
          <br />
          <TextButton
            style={{ margin: "0.25rem 0", width: "100%" }}
            text="Ok"
            variant="contained"
            color="primary"
            onClick={handleDoneClick}
          />
        </div>
      ) : (
        <div>
          <p>
            According to your recent PayCheck behaviour, we have detected that
            you might be under severe financial pressure. We have a dedicated
            team ready and available to try and financially assist you through
            difficult times. Would you like any help during this time?
          </p>
          <br />
          <TextButton
            style={{ margin: "0.25rem 0", width: "100%" }}
            text="Yes"
            variant="contained"
            color="primary"
            onClick={handleRequireAssistance}
          />
          <TextButton
            style={{
              margin: "0.25rem 0",
              width: "100%",
              backgroundColor: red.A400,
            }}
            text="No"
            variant="contained"
            color="primary"
            onClick={handleOnClose}
          />
        </div>
      )}
    </Modal>
  )
}
3

interface PaycheckApplicationModalProps {
  displayPaycheckTransaction: boolean
  isLoading: boolean
  hasError: boolean
  error?: string
  handleOnClose: () => void
  handleOnDone: () => void
}

export const PaycheckApplicationModal: React.FunctionComponent<PaycheckApplicationModalProps> = ({
  displayPaycheckTransaction,
  isLoading,
  hasError,
  error,
  handleOnClose,
  handleOnDone,
}: PaycheckApplicationModalProps) => {
  return (
    <Modal
      open={displayPaycheckTransaction}
      onClose={handleOnClose}
      title="Paycheck"
      loading={isLoading}
      fullWidth={false}
      disableBackdropClose={true}
    >
      {!hasError ? (
        <>
          <p>Your transaction has successfully been submitted.</p>
          <br />
          <TextButton
            style={{ margin: 0, width: "100%" }}
            text="Done"
            variant="contained"
            color="primary"
            onClick={handleOnDone}
          />
        </>
      ) : (
        <>
          <p>
            Something went wrong: {error}, Please contact the administrator.
          </p>
        </>
      )}
    </Modal>
  )
}

interface PaycheckConfirmationModalProps {
  displayConfirmation: boolean
  advanceAmount: number
  isCelbux: boolean
  celbuxNumber: string
  handleOnClose: () => void
  handleOnConfirm: () => void
  isLoading: boolean
}

export const PaycheckConfirmationModal: React.FunctionComponent<PaycheckConfirmationModalProps> = ({
  displayConfirmation,
  advanceAmount,
  isCelbux,
  celbuxNumber,
  handleOnClose,
  handleOnConfirm,
  isLoading,
}: PaycheckConfirmationModalProps) => {
  return (
    <Modal
      open={displayConfirmation}
      onClose={handleOnClose}
      title="Confirmation"
      loading={isLoading}
      fullWidth={false}
    >
      <>
        <p>
          You are about to apply for a Paycheck transaction of R
          {formatAmount(advanceAmount)}
        </p>
        {isCelbux && (
          <p>
            <b>
              This transaction will be made into your celbux number:{" "}
              {celbuxNumber}
            </b>
          </p>
        )}

        <p>
          Please confirm if you would like to proceed with this transaction
          subject to terms and conditions.
        </p>
        <br />
        <TextButton
          style={{ margin: "0.25rem 0", width: "100%" }}
          text="Confirm"
          variant="contained"
          color="primary"
          onClick={handleOnConfirm}
        />
        <TextButton
          style={{ margin: "0.25rem 0", width: "100%" }}
          text="Cancel"
          variant="contained"
          color="primary"
          onClick={handleOnClose}
        />
      </>
    </Modal>
  )
}

interface PaycheckTermAndConditionModalProps {
  displayTcAndCsInModal: boolean
  handleOnClose: () => void
}

export const PaycheckTermAndConditionModal: React.FunctionComponent<PaycheckTermAndConditionModalProps> = ({
  displayTcAndCsInModal,
  handleOnClose,
}: PaycheckTermAndConditionModalProps) => {
  return (
    <Modal
      open={displayTcAndCsInModal}
      onClose={handleOnClose}
      title="Terms and Conditions"
      loading={false}
      fullWidth={true}
    >
      <Disclaimer />
    </Modal>
  )
}

interface PrivacyPolicyModalProps {
  displayPrivacyPolicy: boolean;
  handleOnClose: () => void;
}

export const PrivacyPolicyModal: React.FunctionComponent<PrivacyPolicyModalProps> = ({
  displayPrivacyPolicy,
  handleOnClose,
}: PrivacyPolicyModalProps) => {
  return (
    <Modal
      open={displayPrivacyPolicy}
      onClose={handleOnClose}
      title="Privacy Policy"
      loading={false}
      fullWidth={true}
    >
      <PrivacyPolicyContent />
    </Modal>
  );
};
