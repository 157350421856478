import * as React from "react"

import { SEO } from "."
import Dashboard from "./dashboard"
import AppContainer from "./AppContainer"

const productName =
  (process.env.PRODUCT_NAME || "paycheck").charAt(0).toUpperCase() +
  (process.env.PRODUCT_NAME || "paycheck").slice(1)

const TITLE = `Welcome To Your ${productName}`

const DashboardPage: React.FC<{}> = () => (
  <div>
    <SEO title={TITLE} />
    <AppContainer title={TITLE}>
      <Dashboard />
    </AppContainer>
  </div>
)

export default DashboardPage
