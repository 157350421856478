import { navigate } from "gatsby"
import React from "react"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import BalanceWallet from "./icons/BalanceWallet"
import { PaycheckTermAndConditionModal } from "./dashboard/elements/modals"

const useStyles = makeStyles((theme) => ({
  ListItem: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  listItemIcon: {
    minWidth: "initial",
    paddingRight: "10px",
  },
  listItemText: {
    color: theme.palette.primary.dark,
    fontSize: "0.8rem",
  },
}))

interface IDrawerListProps {
  hidden: boolean
}
const productyName =
  (process.env.PRODUCT_NAME || "paycheck").charAt(0).toUpperCase() +
  (process.env.PRODUCT_NAME || "paycheck").slice(1)

const DrawerList: React.FC<IDrawerListProps> = ({
  hidden,
}: IDrawerListProps) => {
  const classes = useStyles()

  const [displayTcAndCsInModal, setDisplayTcAndCsInModal] = React.useState(
    false,
  )

  const showTermsAndConditions = () => {
    setDisplayTcAndCsInModal(true)
  }

  return (
    <>
      <List>
        <ListItem
          className={classes.ListItem}
          button
          onClick={(): Promise<void> => navigate("/app")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <BalanceWallet />
          </ListItemIcon>
          <ListItemText
            hidden={hidden}
            classes={{ primary: classes.listItemText }}
            primary={`Your ${productyName}`}
          />
        </ListItem>
        <ListItem
          className={classes.ListItem}
          button
          onClick={showTermsAndConditions}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <BalanceWallet />
          </ListItemIcon>
          <ListItemText
            hidden={hidden}
            classes={{ primary: classes.listItemText }}
            primary={`Terms and Conditions`}
          />
        </ListItem>
      </List>
      <PaycheckTermAndConditionModal
        displayTcAndCsInModal={displayTcAndCsInModal}
        handleOnClose={() => setDisplayTcAndCsInModal(false)}
      />
    </>
  )
}

export default DrawerList
