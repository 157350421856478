import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import React, { FC } from "react"
import { isMobile } from "react-device-detect"
import { useGlobalStyles } from "../../styles/globalStyles"
import { formatAmount } from "../../utils/format"
import TextButton from "../elements/TextButton"
import BalanceWallet from "../icons/BalanceWallet"
import TransactionSideBar from "./TransactionSideBar"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "30px 0px",
    },
    amountSpan: {
      padding: "0 8px",
      fontSize: isMobile ? "1.8rem" : "2.2rem",
    },
    amountHeader: {
      fontWeight: "normal",
      marginTop: "-15px",
      marginBottom: "5px",
    },
    amountInfoSpan: {
      fontSize: isMobile ? "0.7rem" : "0.8rem",
    },
    amountFooter: {},
    amountBody: {
      marginBottom: "20px",
    },
    applyForWithdrawal: {
      borderRadius: "8px",
      cursor: "pointer",
    },
    applyForWithdrawalButton: {
      color: "#fff",
      padding: "0 15px!important",
      textAlign: "left",
      margin: isMobile ? "4px" : "20px -15px 0px -15px!important",
      lineHeight: "1.2",
      backgroundColor: theme.palette.primary.dark,
    },
    closeButton: {
      borderRadius: "50%",
      background: "#fff",
      width: "initial",
      minWidth: "initial",
      padding: "6px",
      float: "right",
    },
    mobileApplyForWithdrawalButton: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      right: 0,
      bottom: 0,
      height: "10%",
      width: "100%",
      zIndex: 1200,
      overflowY: "auto",
    },
  }),
)

interface EarningsAndFundsProps {
  employee: any
  refetch: any
  allowPaycheck: boolean
}

const EarningsAndFunds: FC<EarningsAndFundsProps> = ({
  employee,
  refetch,
  allowPaycheck,
}) => {
  const productName =
    (process.env.PRODUCT_NAME || "paycheck").charAt(0).toUpperCase() +
    (process.env.PRODUCT_NAME || "paycheck").slice(1)
  const globalStyles = useGlobalStyles()
  const classes = useStyles()
  const [openTransationSideBar, setOpenTransationSideBar] = React.useState(
    false,
  )

  const handleTransactionClose = () => {
    setOpenTransationSideBar(false)
  }

  return (
    <Box
      component={isMobile ? Paper : Grid}
      display="flex"
      flexWrap="wrap"
      width="100%"
      boxSizing="border-box"
      p={1}
      className={classes.root}
    >
      <Grid item sm={4}>
        <div className={globalStyles.padding}>
          <h4 className={`${classes.amountHeader}`}>Your Earnings</h4>
          <div className={classes.amountBody}>
            <span
              className={`${globalStyles.borderLeft} ${classes.amountSpan}`}
            >
              R{formatAmount(employee.earnedAmount)}
            </span>
          </div>
          <div className={classes.amountFooter}>
            <span
              className={`${globalStyles.textGrey} ${classes.amountInfoSpan}`}
            >
              Determined by the date, number of work days in this Work cycle.
            </span>
          </div>
        </div>
      </Grid>
      <Grid item sm={4}>
        <div className={globalStyles.padding}>
          <h4 className={`${classes.amountHeader} ${globalStyles.textDark}`}>
            Funds Available today
          </h4>
          <div className={classes.amountBody}>
            <span
              className={`${globalStyles.borderLeft} ${classes.amountSpan} ${globalStyles.textDark} ${globalStyles.boldText}`}
            >
              R{formatAmount(employee.accessibleAmount)}
            </span>
          </div>
          <div className={classes.amountFooter}>
            <span
              className={`${globalStyles.textDark} ${classes.amountInfoSpan}`}
            >
              Based on the terms and conditions set by your employer.
            </span>
          </div>
        </div>
      </Grid>
      <Grid item sm={4} hidden={isMobile}>
        <div
          className={`${globalStyles.padding} ${globalStyles.backgroundMain} ${classes.applyForWithdrawal}`}
          onClick={() => {
            setOpenTransationSideBar(true)
          }}
          hidden={!allowPaycheck}
        >
          <BalanceWallet color="#fff" />
          <br />
          <TextButton
            text={`Apply for ${productName} Withdrawal`}
            endIcon={<ChevronRightIcon />}
            className={classes.applyForWithdrawalButton}
          />
        </div>
      </Grid>
      <Grid item sm={12} hidden={!isMobile}>
        <div
          className={`${globalStyles.padding} ${globalStyles.backgroundMain} ${classes.mobileApplyForWithdrawalButton}`}
          onClick={() => {
            setOpenTransationSideBar(true)
          }}
          hidden={!allowPaycheck}
        >
          <BalanceWallet color="#fff" />
          <TextButton
            text={`Apply for ${productName} Withdrawal`}
            endIcon={<ChevronRightIcon />}
            className={classes.applyForWithdrawalButton}
          />
        </div>
      </Grid>
      <TransactionSideBar
        employeeId={employee.id}
        celbuxNumber={employee.celbuxMobileNumber}
        accessibleAmount={employee.accessibleAmount}
        onClose={handleTransactionClose}
        open={openTransationSideBar}
        refetch={refetch}
      />
    </Box>
  )
}

export default EarningsAndFunds
