import { useMutation } from "@apollo/react-hooks"
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { useGlobalStyles } from "../../styles/globalStyles"
import TextButton from "../elements/TextButton"
import BankCardCheck from "../icons/BankCardCheck"
import { DEFAULT_MESSAGE } from "./constants"
import {
  PaycheckApplicationModal,
  PaycheckConfirmationModal,
  PaycheckIndexModal,
  PaycheckTermAndConditionModal,
} from "./elements/modals"
import { PaycheckInput, PaycheckInputAdornment } from "./elements/sidebar"
import {
  CREATE_ADVANCE_MUTATION,
  GENERATE_ADVANCE_QUOTE_MUTATION,
  REQUEST_ADVANCE_PAYMENT_OPTIONS,
  REQUEST_EMPLOYEE_INDEX_STATUS,
} from "./graphql"
import SideBarTable from "./tables/SideBarTable"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transactionSideBar: {
      position: "fixed",
      right: 0,
      top: 0,
      height: "100%",
      width: isMobile ? "100%" : "28%",
      background: theme.palette.secondary.main,
      zIndex: 1300,
      padding: "35px 25px",
      overflowY: "auto",
    },
    closeButton: {
      borderRadius: "50%",
      background: "#fff",
      width: "initial",
      minWidth: "initial",
      padding: "6px",
      float: "right",
    },
    transactionWrapper: {
      borderRadius: "5px",
      background: theme.palette.secondary.dark,
      clear: "both",
      minHeight: "100px",
      marginTop: "30px",
      position: "relative",
      color: "#fff",
      padding: "25px 15px",
    },
    transactionStep: {},
    transactionStepHeader: {
      paddingLeft: "32px",
      display: "flex",
      flexDirection: "row",
    },
    transactionStepBody: {
      padding: "10px 0 27px 0",
    },
    transactionStepNumber: {
      padding: "10px",
      background: "#fff",
      color: theme.palette.primary.dark,
      borderRadius: "50%",
      display: "block",
      minWidth: "32px",
      textAlign: "center",
      height: "32px",
      lineHeight: 1,
      marginLeft: "-36px",
    },
    transactionStepText: {
      lineHeight: 1,
      alignSelf: "center",
      display: "block",
      marginLeft: "6px",
      fontSize: isMobile ? "11px" : "13px",
    },
    transactionPaymentOptionText: {
      marginLeft: "2rem",
      fontWeight: "bold",
      color: "#A5C2D6",
    },
    transactionQuote: {
      background: theme.palette.secondary.main,
      width: "100%",
      minHeight: "40px",
      borderRadius: "5px",
      color: theme.palette.primary.dark,
      padding: "15px",
      overflowX: "auto",
    },
    quoteDatesSmall: {
      color: "#A5C2D6",
    },
    quoteDateSpan: {
      color: "#ffffff",
    },
    linkItem: {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  }),
)

const TransactionSideBar: React.FC<any> = (props: any) => {
  const productName =
    (process.env.PRODUCT_NAME || "paycheck").charAt(0).toUpperCase() +
    (process.env.PRODUCT_NAME || "paycheck").slice(1)
  const globalStyles = useGlobalStyles()
  const classes = useStyles()
  const {
    onClose,
    open,
    accessibleAmount,
    refetch,
    employeeId,
    celbuxNumber,
  } = props
  const [submitAdvanceAmount, generateAdvanceQuoteRes] = useMutation(
    GENERATE_ADVANCE_QUOTE_MUTATION,
  )
  const [requestPaymentOptions, requestPaymentOptionRes] = useMutation(
    REQUEST_ADVANCE_PAYMENT_OPTIONS,
  )
  const [
    requestEmployeeIndexStatus,
    requestEmployeeIndexStatusRes,
  ] = useMutation(REQUEST_EMPLOYEE_INDEX_STATUS)

  const [submitCreateAdvance, createAdvanceRes] = useMutation(
    CREATE_ADVANCE_MUTATION,
  )
  const [submittingCreateAdvance, setSubmittingCreateAdvance] = React.useState(
    false,
  )
  const [advanceAmountError, setAdvanceAmountError] = React.useState("")

  const [acceptedTsAndCs, setAcceptedTsAndCs] = React.useState(false)
  const [tAndCTimestamp, setTAndCTimestamp] = React.useState("")
  const [displayTcAndCsInModal, setDisplayTcAndCsInModal] = React.useState(
    false,
  )
  const [
    displayPaycheckTransaction,
    setDisplayPaycheckTransaction,
  ] = React.useState(false)
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [
    displayPaycheckIndexModal,
    setDisplayPaycheckIndexModal,
  ] = React.useState(false)

  const [enteredAmount, setEnteredAmount] = React.useState("")
  const setInitialQuoteData = () => {
    return {
      quote: generateAdvanceQuoteRes.data?.generateAdvanceQuote?.quote,
      quoteLoading: generateAdvanceQuoteRes.loading,
      quoteMessage: generateAdvanceQuoteRes.error
        ? generateAdvanceQuoteRes.error.message
        : DEFAULT_MESSAGE,
    }
  }

  const [advanceData, setAdvanceData] = useState({
    advance: createAdvanceRes.data?.createAdvance?.advance,
    createAdvanceError: createAdvanceRes.error?.message,
  })
  const [quoteData, setQuoteData] = useState(setInitialQuoteData())
  const [paymentOptions, setPaymentOptions] = useState([])
  const [selectedPaymentOption, setSelectedPaymentOption] = React.useState("")

  const isCelbux = selectedPaymentOption === "celbux"

  const isAdvanceAmountValid = (amount: number) => {
    if (isNaN(amount)) {
      return { valid: false, message: "Amount must be a valid number" }
    } else if (amount > accessibleAmount) {
      return {
        valid: false,
        message: `Amount cannot exceed the accessible amount of R${accessibleAmount}`,
      }
    } else if (amount <= 0) {
      return { valid: false, message: "Amount must be greater than 0" }
    }
    return { valid: true, message: "" }
  }

  const handleAdvanceAmountChange = () => {
    const advanceAmount = parseFloat(enteredAmount)
    const { valid, message } = isAdvanceAmountValid(advanceAmount)
    setAdvanceAmountError(message)
    if (valid && selectedPaymentOption) {
      submitAdvanceAmount({
        variables: {
          advanceAmount,
          paymentOption: selectedPaymentOption,
        },
      })
        .then((data: any) => {
          setQuoteData({
            ...quoteData,
            quote: data.data?.generateAdvanceQuote?.quote,
            quoteLoading: data.data?.loading,
            quoteMessage: data.data?.error
              ? data?.error.message
              : DEFAULT_MESSAGE,
          })
        })
        .catch((error) => {
          setQuoteData({
            ...quoteData,
            quote: null,
            quoteMessage: error.message,
          })
        })
    }
  }
  let executionTimer: NodeJS.Timeout | undefined

  useEffect(() => {
    if (employeeId && enteredAmount.length > 2) {
      if (executionTimer) {
        clearTimeout(executionTimer)
      }
      executionTimer = setTimeout(() => {
        requestPaymentOptions({
          variables: {
            employee: employeeId,
            platform: "web",
            requestAmount: +enteredAmount ?? 0,
          },
        })
      }, 500)
    }
  }, [employeeId, enteredAmount])

  useEffect(() => {
    setPaymentOptions(
      requestPaymentOptionRes?.data?.requestPaymentOption?.paymentOptions
        ? JSON.parse(
            requestPaymentOptionRes?.data?.requestPaymentOption?.paymentOptions,
          )
        : [],
    )
  }, [requestPaymentOptionRes?.data])

  useEffect(() => {
    if (enteredAmount && selectedPaymentOption) {
      handleAdvanceAmountChange()
    }
  }, [enteredAmount, selectedPaymentOption])

  useEffect(() => {
    setSelectedPaymentOption("")
  }, [paymentOptions])

  const handlePaymentOptionRadioChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setSelectedPaymentOption(event.target.value)
  }

  const handleTransactionTcAndCsCheck = () => {
    setTAndCTimestamp(new Date().toLocaleString())
    setAcceptedTsAndCs(!acceptedTsAndCs)
  }

  const handlePaycheckTransactionApplication = () => {
    const { advanceAmount, ...quote } = quoteData.quote
    const termAndConditionAcceptTimestamp = tAndCTimestamp
    setSubmittingCreateAdvance(true)
    submitCreateAdvance({
      variables: {
        advanceAmount: advanceAmount,
        termAndConditionAcceptTimestamp: termAndConditionAcceptTimestamp,
        paymentOption: selectedPaymentOption,
      },
    })
      .then((data: any) => {
        setAdvanceData({
          ...advanceData,
          advance: data.data?.createAdvance?.advance,
        })
        setQuoteData({
          ...quoteData,
          quote: data.data?.generateAdvanceQuote?.quote,
          quoteLoading: data.data?.loading,
          quoteMessage: data.data?.error
            ? data?.error.message
            : DEFAULT_MESSAGE,
        })
      })
      .catch((error) => {
        setAdvanceData({
          ...advanceData,
          createAdvanceError: error.message,
        })
      })
      .finally(() => {
        setDisplayConfirmation(false)
        requestEmployeeIndexStatus({
          variables: {
            employee: employeeId,
          },
        }).then((data) => {
          const showIndexModal =
            data?.data?.getEmployeeIndexAlertStatus?.hasFinancialDistressAlert
          if (showIndexModal) {
            setDisplayPaycheckIndexModal(showIndexModal)
            setDisplayPaycheckTransaction(!showIndexModal)
          } else {
            setDisplayPaycheckTransaction(true)
          }
        })
      })
  }

  const handleDoneClick = () => {
    refetch()
    onClose()
    setSubmittingCreateAdvance(false)
    setDisplayPaycheckTransaction(false)
    setAcceptedTsAndCs(false)
    setEnteredAmount("")
    setTAndCTimestamp("")
    setSelectedPaymentOption("")
    setQuoteData({ ...setInitialQuoteData(), quote: null })
  }

  const handleApplyClick = () => {
    setDisplayConfirmation(true)
  }

  const handleModalClose = () => {
    if (submittingCreateAdvance) {
      handleDoneClick()
    } else {
      setDisplayPaycheckTransaction(false)
    }
  }

  const canClickApply = () => {
    if (quoteData.quote && acceptedTsAndCs && selectedPaymentOption) {
      return true
    }
    return false
  }

  const handleEnteredAmountChange = (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    setEnteredAmount(event.target.value)
  }

  const showTermsAndConditions = () => {
    setDisplayTcAndCsInModal(true)
  }

  return (
    <>
      <div
        style={{ display: open ? "block" : "none" }}
        className={classes.transactionSideBar}
      >
        <div style={{ clear: "both", height: "38px" }}>
          <Button className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.transactionWrapper}>
          <h4 className={globalStyles.paperHead}>
            <BankCardCheck /> Request{" "}
            <span style={{ textTransform: "capitalize" }}>{productName}</span>{" "}
            transaction
          </h4>
          <div>
            <div className={classes.transactionStep}>
              <div className={classes.transactionStepHeader}>
                <span className={classes.transactionStepNumber}>1</span>{" "}
                <span className={classes.transactionStepText}>
                  Specify the amount you would like as a{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {productName}
                  </span>{" "}
                  for this transaction.
                </span>
              </div>
              <div className={classes.transactionStepBody}>
                <FormControl error={advanceAmountError != ""}>
                  <PaycheckInput
                    onChange={handleEnteredAmountChange}
                    startAdornment={
                      <PaycheckInputAdornment position="start">
                        R
                      </PaycheckInputAdornment>
                    }
                    id="advanceAmount"
                    value={enteredAmount}
                  />
                  {advanceAmountError != "" ? (
                    <FormHelperText
                      style={{
                        background: "#fff",
                        borderRadius: "5px",
                        padding: "4px",
                      }}
                    >
                      {advanceAmountError}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
            </div>
            <div className={classes.transactionStep}>
              <div className={classes.transactionStepHeader}>
                <span className={classes.transactionStepNumber}>2</span>{" "}
                <span className={classes.transactionStepText}>
                  Select a Payment Option
                </span>
              </div>

              <div className={classes.transactionStepBody}>
                <FormControl error={advanceAmountError != ""}>
                  <RadioGroup
                    aria-label="payment_options"
                    name="selectPaymentOption"
                    value={selectedPaymentOption}
                    onChange={handlePaymentOptionRadioChange}
                  >
                    {paymentOptions.map((item) => (
                      <FormControlLabel
                        style={{ fontSize: "14px !important" }}
                        key={item[0]}
                        value={item[0]}
                        control={<Radio />}
                        label={
                          <span className={classes.transactionStepText}>
                            {item[1]}
                          </span>
                        }
                      />
                    ))}
                    {!requestPaymentOptionRes.loading &&
                      !paymentOptions.length && (
                        <span className={classes.transactionPaymentOptionText}>
                          You do not have any payment options on your account.
                        </span>
                      )}
                    {requestPaymentOptionRes.loading &&
                      !paymentOptions.length && (
                        <span className={classes.transactionPaymentOptionText}>
                          Fetching payment option, please wait...
                        </span>
                      )}
                  </RadioGroup>
                  {isCelbux && (
                    <FormHelperText
                      style={{
                        color: "lawngreen",
                        fontSize: "14px",
                        borderRadius: "5px",
                        padding: "4px",
                      }}
                    >
                      Transaction will made into the celbux number:{" "}
                      <b>{celbuxNumber}</b>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div className={classes.transactionStep}>
              <div className={classes.transactionStepHeader}>
                <span className={classes.transactionStepNumber}>3</span>{" "}
                <span className={classes.transactionStepText}>
                  Projected transaction details.
                </span>
              </div>
              <div className={classes.transactionStepBody}>
                <div className={classes.transactionQuote}>
                  {quoteData.quoteLoading || generateAdvanceQuoteRes.loading ? (
                    <CircularProgress />
                  ) : quoteData.quote ? (
                    <SideBarTable quoteData={quoteData} />
                  ) : (
                    quoteData.quoteMessage
                  )}
                </div>
              </div>
            </div>
            <div className={classes.transactionStep}>
              <div className={classes.transactionStepHeader}>
                <span className={classes.transactionStepNumber}>4</span>{" "}
                <span className={classes.transactionStepText}>
                  I have read the{" "}
                  <a
                    onClick={showTermsAndConditions}
                    className={classes.linkItem}
                  >
                    terms and conditions
                  </a>{" "}
                  <Checkbox
                    checked={acceptedTsAndCs}
                    onChange={handleTransactionTcAndCsCheck}
                    name="personal.nameAndSurname"
                  />
                </span>
              </div>
              <div className={classes.transactionStepBody}></div>
            </div>
            <TextButton
              disabled={!canClickApply()}
              onClick={handleApplyClick}
              style={{ width: "100%", color: "#ffffff" }}
              text={`Apply for ${productName} Withdrawal`}
            />
          </div>
        </div>
      </div>
      {/* MODAL SECTION */}
      <PaycheckTermAndConditionModal
        displayTcAndCsInModal={displayTcAndCsInModal}
        handleOnClose={() => setDisplayTcAndCsInModal(false)}
      />
      <PaycheckConfirmationModal
        displayConfirmation={displayConfirmation}
        advanceAmount={quoteData.quote?.advanceAmount}
        isCelbux={isCelbux}
        celbuxNumber={celbuxNumber}
        handleOnClose={() => setDisplayConfirmation(false)}
        handleOnConfirm={handlePaycheckTransactionApplication}
        isLoading={createAdvanceRes.loading}
      />
      <PaycheckApplicationModal
        displayPaycheckTransaction={displayPaycheckTransaction}
        isLoading={createAdvanceRes.loading}
        hasError={!(advanceData.advance && !advanceData.createAdvanceError)}
        error={advanceData.createAdvanceError}
        handleOnClose={handleModalClose}
        handleOnDone={handleDoneClick}
      />
      <PaycheckIndexModal
        displayPaycheckIndexModel={displayPaycheckIndexModal}
        handleOnClose={() => {
          setDisplayPaycheckIndexModal(false)
          setDisplayPaycheckTransaction(true)
        }}
        employeeId={employeeId}
      />
    </>
  )
}

export default TransactionSideBar
